import React from "react";
import { FunctionComponent } from "react";
import { MatterType } from "@/helpers/types";
import Link from "next/link";
import styles from "../../styles/components/MattersList/DesktopListItem.module.scss";
import { showImg, showLead } from "./helpers";
import classNames from "classnames";
import PlayIcon from "../PlayIcon";
import dynamic from "next/dynamic";
import Img from "../Img";
import { date } from "@/helpers/date";

const ContentBlocks = dynamic(() => import("../ContentBlocks"));

type PropsType = {
  matter: MatterType;
  withContentBlocks?: boolean;
  showLiveTitle?: boolean;
};

const DesktopListItem: FunctionComponent<PropsType> = ({
  matter,
  withContentBlocks,
  showLiveTitle,
}) => {
  const leadVisible = withContentBlocks
    ? true
    : !showImg(matter) && showLead(matter);

  const isLive = matter.direct_link_only && !matter.lead;

  return (
    <div className="mb-8">
      <Link href={matter.path || "/"}>
        <a
          className={classNames({
            [styles.withImg]: showImg(matter),
          })}
        >
          {showImg(matter) && (
            <div>
              <div className="relative">
                <Img src={matter.image?.image_250} />
                {matter.has_video && <PlayIcon size={52} />}
              </div>
            </div>
          )}
          <div className={styles.textCol}>
            {(!isLive || (isLive && showLiveTitle)) && (
              <div className={styles.title}>
                {matter.highlighted_title ? (
                  <span className="text-active">
                    {matter.highlighted_title}.{" "}
                  </span>
                ) : null}
                {matter.title}
              </div>
            )}
            {leadVisible && !!matter.lead && (
              <div className={styles.lead}>{matter.lead}</div>
            )}
            <div className={styles.date}>
              {date(
                matter.published_at || matter.published_at_in_timezone.iso,
                { dontShowToday: isLive }
              )}
            </div>
          </div>
        </a>
      </Link>
      {withContentBlocks && matter.content_blocks && matter.direct_link_only && (
        // matter.priority !== "low" &&
        // matter.priority !== "normal" &&
        <div className="my-4">
          <ContentBlocks items={matter.content_blocks} />
          {(matter.content_blocks_count || 0) - matter.content_blocks.length >
            1 && (
            <div className="-mt-2 mb-4">
              <Link href={matter.path}>
                <a className="text-[15px] text-active hover:underline">
                  Читать полностью
                </a>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(DesktopListItem);
