import { useDevice } from "@/helpers/deviceContext";
import classNames from "classnames";
import { FunctionComponent } from "react";

type PropsType = { onClick?: () => void; size?: number };

const PlayIcon: FunctionComponent<PropsType> = ({ onClick, size }) => {
  const { isMobile } = useDevice();
  const finalSize = size || (isMobile ? 64 : 80);

  return (
    <svg
      className={classNames("absolute", onClick ? "cursor-pointer" : "")}
      style={{
        left: `calc(50% - ${finalSize / 2}px)`,
        top: `calc(50% - ${finalSize / 2}px)`,
      }}
      width={finalSize}
      height={finalSize}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <path
        style={{ fill: "var(--active-color)" }}
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
      />
      <path
        d="M39.1445 30.2678C40.4779 31.0376 41.1445 31.4225 41.1445 31.9999C41.1445 32.5772 40.4779 32.9621 39.1445 33.7319L30.3389 38.8158C28.7899 39.7102 28.0153 40.1574 27.5133 39.863C27.0114 39.5685 27.0238 38.6621 27.0488 36.8491C27.0656 35.6253 27.0772 34.0412 27.0772 31.9999C27.0772 29.9586 27.0656 28.3744 27.0488 27.1506C27.0238 25.3377 27.0114 24.4312 27.5133 24.1368C28.0153 23.8424 28.7899 24.2895 30.3389 25.1839L39.1445 30.2678Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIcon;
