import {
  Dispatch,
  FunctionComponent,
  RefObject,
  SetStateAction,
  useState,
} from "react";
import { useDevice } from "@/helpers/deviceContext";
import classNames from "classnames";
import Image from "next/image";

type PropsType = {
  src?: string;
  fullscreenSrc?: string;
  alt?: string;
  loading?: "lazy" | "eager";
  className?: string;
  fullScreen?: RefObject<HTMLDivElement>;
  setDocFS?: Dispatch<SetStateAction<boolean>>;
  imageClassName?: string;
};

const Img: FunctionComponent<PropsType> = ({
  src = "",
  fullscreenSrc = "",
  alt = "",
  loading = "lazy",
  className = "",
  fullScreen = null,
  imageClassName = "",
  setDocFS = () => {},
}) => {
  const { isDesktop } = useDevice();
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <div
      className={classNames("relative bg-gray-200 overflow-hidden", className, {
        ["aspect-[16/9]"]: isDesktop,
      })}
    >
      {isDesktop && (
        <img
          className="absolute left-0 top-0 w-full h-full object-cover blur-lg"
          src={imgSrc}
          alt={alt}
          loading={loading}
          /*layout="fill"*/
        />
      )}
      <img
        className={classNames("w-full h-full", imageClassName, {
          ["absolute left-0 top-0 object-center object-contain"]: isDesktop,
        })}
        src={imgSrc}
        /*width="100%"
        height="56%"*/
        /*layout={isDesktop ? "fill" : "responsive"}*/
        alt={alt}
        loading={loading}
        onClickCapture={(e) => {
          const el = e.target as HTMLImageElement;
          if (
            !fullscreenSrc ||
            !("requestFullscreen" in el) ||
            !fullScreen ||
            !fullScreen.current
          )
            return;
          setImgSrc(fullscreenSrc);
          if (document.fullscreenElement) {
            setDocFS(false);
            void document.exitFullscreen();
          } else {
            setDocFS(true);
            void fullScreen.current.requestFullscreen();
          }
        }}
      />
    </div>
  );
};

export default Img;
